var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"RqMainTemperature"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"zhuangshizb"}),_c('div',{staticClass:"zhuangshizj"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数2")]),_c('div',{staticClass:"zhuangshiyb",on:{"click":_vm.closeCompon}},[_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])])]),_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"first_line flex"},[_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"one_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"words"},[_vm._v("SP")]),_c('div',{staticClass:"shadow num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWSP2,'SP','QWSP2','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWSP2)+" ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"words"},[_vm._v("PV")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'TICA_117_B','MCSOUREC','TICA_117_NG3','一甲出口蒸汽温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCSOUREC.TICA_117_B,'TICA_117_B','MCSOUREC','TICA_117_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TICA_117_B)+" ")])]),_c('div',{staticClass:"line3 flex"},[_c('div',{staticClass:"words"},[_vm._v("TC")]),_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWTC1)+" ")])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"button buttonx",style:({
							background:_vm.infoList.MCXK__p__XK14 && !_vm.infoList.MCXK__p__XK14.RM
					        ? '#2AFC30'
					        : 'red',
							}),on:{"click":function($event){_vm.infoList.MCXK__p__XK14
							? _vm.toDetail(1, 'XK14', 'MCXK__p__XK14', '', '一级甲主汽温度'): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button buttonk",style:({
							background:_vm.infoList.MCQK__p__QK14 && !_vm.infoList.MCQK__p__QK14.TS
					        ? '#2AFC30'
					        : 'red',}),on:{"click":function($event){_vm.infoList.MCQK__p__QK14
							    ? _vm.toDetail(2,'QK14','MCQK__p__QK14','','一级甲入口蒸汽温度前馈'): ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"buttona",style:({
				     	background:_vm.infoList.MCMAN__p__MAN3.RM && _vm.infoList.MCMAN__p__MAN3.RM == 1
				        ? '#2AFC30'
				        : 'red',
				     	}),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN3
				     	? _vm.toCompon(0,'AV','MCMAN__p__MAN3','MAN3_AV_NG3','一级甲减温水手操器MAN3','KF_103_B','MCSOUREC','KF_103_NG3'): ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"four_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"word"},[_vm._v("B01-A0")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'AV','MCMAN__p__MAN3','MAN3_AV_NG3','一级甲减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCMAN__p__MAN3.AV,'AV','MCMAN__p__MAN3','MAN3_AV_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCMAN__p__MAN3.AV)+" ")])]),_vm._m(1)])]),_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"one_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"words"},[_vm._v("SP")]),_c('div',{staticClass:"shadow num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWSP,'SP','QWSP','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWSP)+" ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"words"},[_vm._v("PV")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'TICA_103_B','MCSOUREC','TICA_103_NG3','主汽温度2')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCSOUREC.TICA_103_B,'TICA_103_B','MCSOUREC','TICA_103_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TICA_103_B)+"0.01 ")])]),_c('div',{staticClass:"line3 flex"},[_c('div',{staticClass:"words"},[_vm._v("TC")]),_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWTC1)+" ")])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"button buttonx",style:({
							background:_vm.infoList.MCXK__p__XK2 && !_vm.infoList.MCXK__p__XK2.RM
					        ? '#2AFC30'
					        : 'red',
							}),on:{"click":function($event){_vm.infoList.MCXK__p__XK2
							? _vm.toDetail(1, 'XK2', 'MCXK__p__XK2', '', '二级甲减温先控调节'): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button buttonk",style:({
							background:_vm.infoList.MCQK__p__QK16 && !_vm.infoList.MCQK__p__QK16.TS
					        ? '#2AFC30'
					        : 'red',}),on:{"click":function($event){_vm.infoList.MCQK__p__QK16
							    ? _vm.toDetail(2,'QK16','MCQK__p__QK16','','二级甲入口蒸汽温度前馈'): ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"buttona",style:({
				     	background:_vm.infoList.MCMAN__p__MAN5.RM && _vm.infoList.MCMAN__p__MAN5.RM == 1
				        ? '#2AFC30'
				        : 'red',
				     	}),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN5
				     	? _vm.toCompon(0,'AV','MCMAN__p__MAN5','MAN5_AV_NG3','二级甲减温水手操器MAN5','KF_105_B','MCSOUREC','KF_105_NG3'): ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"four_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"word"},[_vm._v("B01-A0")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'AV','MCMAN__p__MAN5','MAN5_AV_NG3','二级甲减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCMAN__p__MAN5.AV,'AV','MCMAN__p__MAN5','MAN5_AV_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCMAN__p__MAN5.AV)+" ")])]),_vm._m(2)])])]),_c('div',{staticClass:"line_anniu"},[_c('div',{staticClass:"anniu",style:({
					background:_vm.infoList.MCQK__p__QK1 && !_vm.infoList.MCQK__p__QK1.TS
			        ? '#2AFC30'
			        : 'red',}),on:{"click":function($event){_vm.infoList.MCQK__p__QK1
					    ? _vm.toDetail(2,'QK1','MCQK__p__QK1','','总煤气量前馈调节'): ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"first_line second flex"},[_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"one_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"words"},[_vm._v("SP")]),_c('div',{staticClass:"shadow num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWSP2,'SP','QWSP2','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWSP2)+" ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"words"},[_vm._v("PV")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'TICA_119_B','MCSOUREC','TICA_119_NG3','一乙出口蒸汽温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCSOUREC.TICA_119_B,'TICA_119_B','MCSOUREC','TICA_119_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TICA_119_B)+" ")])]),_c('div',{staticClass:"line3 flex"},[_c('div',{staticClass:"words"},[_vm._v("TC")]),_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWTC1)+" ")])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"button buttonx",style:({
							background:_vm.infoList.MCXK__p__XK15 && !_vm.infoList.MCXK__p__XK15.RM
					        ? '#2AFC30'
					        : 'red',
							}),on:{"click":function($event){_vm.infoList.MCXK__p__XK15
							? _vm.toDetail(1, 'XK15', 'MCXK__p__XK15', '', '一级乙主汽温度'): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button buttonk",style:({
							background:_vm.infoList.MCQK__p__QK15 && !_vm.infoList.MCQK__p__QK15.TS
					        ? '#2AFC30'
					        : 'red',}),on:{"click":function($event){_vm.infoList.MCQK__p__QK15
							    ? _vm.toDetail(2,'QK15','MCQK__p__QK15','','一级乙入口蒸汽温度前馈'): ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"buttona",style:({
				     	background:_vm.infoList.MCMAN__p__MAN4.RM && _vm.infoList.MCMAN__p__MAN4.RM == 1
				        ? '#2AFC30'
				        : 'red',
				     	}),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN4
				     	? _vm.toCompon(0,'AV','MCMAN__p__MAN4','MAN4_RM_NG3','一级乙减温水手操器MAN4','KF_104_B','MCSOUREC','KF_104_NG3'): ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"four_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"word"},[_vm._v("B02-A0")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'AV','MCMAN__p__MAN4','MAN4_AV_NG','一级乙减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCMAN__p__MAN4.AV,'AV','MCMAN__p__MAN4','MAN4_AV_NG')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCMAN__p__MAN4.AV)+" ")])]),_vm._m(3)])]),_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"one_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"words"},[_vm._v("SP")]),_c('div',{staticClass:"shadow num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWSP,'SP','QWSP','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWSP)+" ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"words"},[_vm._v("PV")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'TICA_104_B','MCSOUREC','TICA_104_NG3','炉膛出口左侧温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCSOUREC.TICA_104_B,'TICA_104_B','MCSOUREC','TICA_104_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TICA_104_B)+" ")])]),_c('div',{staticClass:"line3 flex"},[_c('div',{staticClass:"words"},[_vm._v("TC")]),_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCQIWEN.QWTC1)+" ")])])]),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"button buttonx",style:({
							background:_vm.infoList.MCXK__p__XK3 && !_vm.infoList.MCXK__p__XK3.RM
					        ? '#2AFC30'
					        : 'red',
							}),on:{"click":function($event){_vm.infoList.MCXK__p__XK3
							? _vm.toDetail(1, 'XK3', 'MCXK__p__XK3', '', '二级乙减温先控调节'): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button buttonk",style:({
							background:_vm.infoList.MCQK__p__QK17 && !_vm.infoList.MCQK__p__QK17.TS
					        ? '#2AFC30'
					        : 'red',}),on:{"click":function($event){_vm.infoList.MCQK__p__QK17
							    ? _vm.toDetail(2,'QK17','MCQK__p__QK17','','二级乙入口蒸汽温度前馈'): ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"buttona",style:({
				     	background:_vm.infoList.MCMAN__p__MAN5.RM && _vm.infoList.MCMAN__p__MAN5.RM == 1
				        ? '#2AFC30'
				        : 'red',
				     	}),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN5
				     	? _vm.toCompon(0,'AV','MCMAN__p__MAN5','MAN5_RM_NG3','二级乙减温水手操器MAN5','KF_105_B','MCSOUREC','KF_105_NG3'): ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"four_div"},[_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"word"},[_vm._v("B04-A0")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(2,'AV','MCMAN__p__MAN6','MAN6_AV_NG3','二级乙减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MCMAN__p__MAN6.AV,'AV','MCMAN__p__MAN6','MAN6_AV_NG3')}}},[_vm._v(" "+_vm._s(_vm.infoList.MCMAN__p__MAN6.AV)+" ")])]),_vm._m(4)])])])]),_vm._m(5),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow=false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow=false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"pmark":_vm.Panualmark,"pnode":_vm.Panualnode,"pnm":_vm.Panualnodem,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v(" 主汽温度优化控制模型 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line2"},[_c('div',{staticClass:"word"},[_vm._v("一级甲")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line2"},[_c('div',{staticClass:"word"},[_vm._v("二级甲")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line2"},[_c('div',{staticClass:"word"},[_vm._v("一级乙")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line2"},[_c('div',{staticClass:"word"},[_vm._v("二级乙")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"explain"},[_c('div',{staticClass:"explain_content"},[_c('div',{staticClass:"explain1 flex"},[_c('div',[_vm._v("说明1：各个阀门的SADD：为各自阀门差1达到上限；")]),_c('div',{staticClass:"blank"}),_c('div',[_vm._v("SSUB: 为各自阀门差1达到下限；")])]),_c('div',{staticClass:"explain2"},[_vm._v("说明2：一减控制一减出口温度，二减直接控制主汽温度，可根据现场实际情况修改方案。")])])])}]

export { render, staticRenderFns }