<template>
	<div class="total drag" v-draw id="RqMainTemperature">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_参数2</div>
				<div class="zhuangshiyb" @click="closeCompon">
					<div class="icon" @click="closeCompon"></div>
				</div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				主汽温度优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="content">
			<!-- 第一行 -->
			<div class="first_line flex">
				<!-- 第一行第一个 -->
				<div class="one flex">
					<div class="one_div">
						<div class="line1 flex">
							<div class="words">SP</div>
							<div class="shadow num1" @click="toIpt(infoList.MCQIWEN.QWSP2,'SP','QWSP2','MCQIWEN')">
								{{infoList.MCQIWEN.QWSP2}}
							</div>
						</div>
						<div class="line2 flex">
							<div class="words">PV</div>
							<div class="shadow num2" @click="toCompon(2,'TICA_117_B','MCSOUREC','TICA_117_NG3','一甲出口蒸汽温度')"
								@dblclick="Cclick(infoList.MCSOUREC.TICA_117_B,'TICA_117_B','MCSOUREC','TICA_117_NG3')">
								{{infoList.MCSOUREC.TICA_117_B}}
							</div>
						</div>
						<div class="line3 flex">
							<div class="words">TC</div>
							<div class="num1" @click="toIpt(infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')">
								{{infoList.MCQIWEN.QWTC1}}
							</div>
						</div>
					</div>
					<div class="bg">
						<div class="button buttonx" :style="{
								background:infoList.MCXK__p__XK14 && !infoList.MCXK__p__XK14.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK14
								? toDetail(1, 'XK14', 'MCXK__p__XK14', '', '一级甲主汽温度'): ''">
							X
						</div>
						<div class="button buttonk" :style="{
								background:infoList.MCQK__p__QK14 && !infoList.MCQK__p__QK14.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK14
								    ? toDetail(2,'QK14','MCQK__p__QK14','','一级甲入口蒸汽温度前馈'): ''">
							K
						</div>
					</div>
					<div class="buttona" :style="{
					     	background:infoList.MCMAN__p__MAN3.RM && infoList.MCMAN__p__MAN3.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN3
					     	? toCompon(0,'AV','MCMAN__p__MAN3','MAN3_AV_NG3','一级甲减温水手操器MAN3','KF_103_B','MCSOUREC','KF_103_NG3'): ''">
						A
					</div>
					<div class="four_div">
						<div class="line1 flex">
							<div class="word">B01-A0</div>
							<div class="shadow num2" @click="toCompon(2,'AV','MCMAN__p__MAN3','MAN3_AV_NG3','一级甲减温水手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN3.AV,'AV','MCMAN__p__MAN3','MAN3_AV_NG3')">
								{{infoList.MCMAN__p__MAN3.AV}}
							</div>
						</div>
						<div class="line2">
							<div class="word">一级甲</div>
						</div>
					</div>
				</div>
				<!-- 第一行第二个 -->
				<div class="one flex">
					<div class="one_div">
						<div class="line1 flex">
							<div class="words">SP</div>
							<div class="shadow num1" @click="toIpt(infoList.MCQIWEN.QWSP,'SP','QWSP','MCQIWEN')">
								{{infoList.MCQIWEN.QWSP}}
							</div>
						</div>
						<div class="line2 flex">
							<div class="words">PV</div>
							<div class="shadow num2" @click="toCompon(2,'TICA_103_B','MCSOUREC','TICA_103_NG3','主汽温度2')"
								@dblclick="Cclick(infoList.MCSOUREC.TICA_103_B,'TICA_103_B','MCSOUREC','TICA_103_NG3')">
								{{infoList.MCSOUREC.TICA_103_B}}0.01
							</div>
						</div>
						<div class="line3 flex">
							<div class="words">TC</div>
							<div class="num1" @click="toIpt(infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')">
								{{infoList.MCQIWEN.QWTC1}}
							</div>
						</div>
					</div>
					<div class="bg">
						<div class="button buttonx" :style="{
								background:infoList.MCXK__p__XK2 && !infoList.MCXK__p__XK2.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK2
								? toDetail(1, 'XK2', 'MCXK__p__XK2', '', '二级甲减温先控调节'): ''">
							X
						</div>
						<div class="button buttonk" :style="{
								background:infoList.MCQK__p__QK16 && !infoList.MCQK__p__QK16.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK16
								    ? toDetail(2,'QK16','MCQK__p__QK16','','二级甲入口蒸汽温度前馈'): ''">
							K
						</div>
					</div>
					<div class="buttona" :style="{
					     	background:infoList.MCMAN__p__MAN5.RM && infoList.MCMAN__p__MAN5.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN5
					     	? toCompon(0,'AV','MCMAN__p__MAN5','MAN5_AV_NG3','二级甲减温水手操器MAN5','KF_105_B','MCSOUREC','KF_105_NG3'): ''">
						A
					</div>
					<div class="four_div">
						<div class="line1 flex">
							<div class="word">B01-A0</div>
							<div class="shadow num2" @click="toCompon(2,'AV','MCMAN__p__MAN5','MAN5_AV_NG3','二级甲减温水手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN5.AV,'AV','MCMAN__p__MAN5','MAN5_AV_NG3')">
								{{infoList.MCMAN__p__MAN5.AV}}
							</div>
						</div>
						<div class="line2">
							<div class="word">二级甲</div>
						</div>
					</div>
				</div>
			</div>
			<!-- K按钮 -->
			<div class="line_anniu">
				<div class="anniu" :style="{
						background:infoList.MCQK__p__QK1 && !infoList.MCQK__p__QK1.TS
				        ? '#2AFC30'
				        : 'red',}" @click="infoList.MCQK__p__QK1
						    ? toDetail(2,'QK1','MCQK__p__QK1','','总煤气量前馈调节'): ''">
					K
				</div>
			</div>
			<!-- 第二行 -->
			<div class="first_line second flex">
				<!-- 第二行第一个 -->
				<div class="one flex">
					<div class="one_div">
						<div class="line1 flex">
							<div class="words">SP</div>
							<div class="shadow num1" @click="toIpt(infoList.MCQIWEN.QWSP2,'SP','QWSP2','MCQIWEN')">
								{{infoList.MCQIWEN.QWSP2}}
							</div>
						</div>
						<div class="line2 flex">
							<div class="words">PV</div>
							<div class="shadow num2" @click="toCompon(2,'TICA_119_B','MCSOUREC','TICA_119_NG3','一乙出口蒸汽温度')"
								@dblclick="Cclick(infoList.MCSOUREC.TICA_119_B,'TICA_119_B','MCSOUREC','TICA_119_NG3')">
								{{infoList.MCSOUREC.TICA_119_B}}
							</div>
						</div>
						<div class="line3 flex">
							<div class="words">TC</div>
							<div class="num1" @click="toIpt(infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')">
								{{infoList.MCQIWEN.QWTC1}}
							</div>
						</div>
					</div>
					<div class="bg">
						<div class="button buttonx" :style="{
								background:infoList.MCXK__p__XK15 && !infoList.MCXK__p__XK15.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK15
								? toDetail(1, 'XK15', 'MCXK__p__XK15', '', '一级乙主汽温度'): ''">
							X
						</div>
						<div class="button buttonk" :style="{
								background:infoList.MCQK__p__QK15 && !infoList.MCQK__p__QK15.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK15
								    ? toDetail(2,'QK15','MCQK__p__QK15','','一级乙入口蒸汽温度前馈'): ''">
							K
						</div>
					</div>
					<div class="buttona" :style="{
					     	background:infoList.MCMAN__p__MAN4.RM && infoList.MCMAN__p__MAN4.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN4
					     	? toCompon(0,'AV','MCMAN__p__MAN4','MAN4_RM_NG3','一级乙减温水手操器MAN4','KF_104_B','MCSOUREC','KF_104_NG3'): ''">
						A
					</div>
					<div class="four_div">
						<div class="line1 flex">
							<div class="word">B02-A0</div>
							<div class="shadow num2" @click="toCompon(2,'AV','MCMAN__p__MAN4','MAN4_AV_NG','一级乙减温水手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN4.AV,'AV','MCMAN__p__MAN4','MAN4_AV_NG')">
								{{infoList.MCMAN__p__MAN4.AV}}
							</div>
						</div>
						<div class="line2">
							<div class="word">一级乙</div>
						</div>
					</div>
				</div>
				<!-- 第二行第二个 -->
				<div class="one flex">
					<div class="one_div">
						<div class="line1 flex">
							<div class="words">SP</div>
							<div class="shadow num1" @click="toIpt(infoList.MCQIWEN.QWSP,'SP','QWSP','MCQIWEN')">
								{{infoList.MCQIWEN.QWSP}}
							</div>
						</div>
						<div class="line2 flex">
							<div class="words">PV</div>
							<div class="shadow num2" @click="toCompon(2,'TICA_104_B','MCSOUREC','TICA_104_NG3','炉膛出口左侧温度')"
								@dblclick="Cclick(infoList.MCSOUREC.TICA_104_B,'TICA_104_B','MCSOUREC','TICA_104_NG3')">
								{{infoList.MCSOUREC.TICA_104_B}}
							</div>
						</div>
						<div class="line3 flex">
							<div class="words">TC</div>
							<div class="num1" @click="toIpt(infoList.MCQIWEN.QWTC1,'TC','QWTC1','MCQIWEN')">
								{{infoList.MCQIWEN.QWTC1}}
							</div>
						</div>
					</div>
					<div class="bg">
						<div class="button buttonx" :style="{
								background:infoList.MCXK__p__XK3 && !infoList.MCXK__p__XK3.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK3
								? toDetail(1, 'XK3', 'MCXK__p__XK3', '', '二级乙减温先控调节'): ''">
							X
						</div>
						<div class="button buttonk" :style="{
								background:infoList.MCQK__p__QK17 && !infoList.MCQK__p__QK17.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK17
								    ? toDetail(2,'QK17','MCQK__p__QK17','','二级乙入口蒸汽温度前馈'): ''">
							K
						</div>
					</div>
					<div class="buttona" :style="{
					     	background:infoList.MCMAN__p__MAN5.RM && infoList.MCMAN__p__MAN5.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN5
					     	? toCompon(0,'AV','MCMAN__p__MAN5','MAN5_RM_NG3','二级乙减温水手操器MAN5','KF_105_B','MCSOUREC','KF_105_NG3'): ''">
						A
					</div>
					<div class="four_div">
						<div class="line1 flex">
							<div class="word">B04-A0</div>
							<div class="shadow num2" @click="toCompon(2,'AV','MCMAN__p__MAN6','MAN6_AV_NG3','二级乙减温水手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN6.AV,'AV','MCMAN__p__MAN6','MAN6_AV_NG3')">
								{{infoList.MCMAN__p__MAN6.AV}}
							</div>
						</div>
						<div class="line2">
							<div class="word">二级乙</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- explain -->
		<div class="explain">
			<div class="explain_content">
				<div class="explain1 flex">
					<div>说明1：各个阀门的SADD：为各自阀门差1达到上限；</div>
					<div class="blank"></div>
					<div>SSUB: 为各自阀门差1达到下限；</div>
				</div>
				<div class="explain2">说明2：一减控制一减出口温度，二减直接控制主汽温度，可根据现场实际情况修改方案。</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<!-- <Firstcontrol 
		:infoList = 'infoList' 
		:isIndex = 'isIndex'
		:historyname = 'Firstcontrolname'
		:titname = 'Firstcontroltitname'
		:node = 'Firstcontrolnode'
		v-if="isFshow"
		@sendStatus = 'isFshow=false'></Firstcontrol> -->
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :pmark="Panualmark" :pnode="Panualnode" :pnm="Panualnodem"
			:infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Panualmark: '',
				Panualnode: '',
				Panualnodem: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Param02', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname,
								this.Panualmark = param1, this.Panualnode = param2, this.Panualnodem = param3
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>
<style lang="scss" scoped>
	.total {
		width: 53vw;
		height: 67vh;
		// background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.zhuangshizb {
			width: 16vw;
			height: 2vh;
			// background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 19vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1.3vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			// background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 16vw;
			height: 2vh;
			// background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 1.9vw;
			height: 3.3vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 13vw;
			margin-top: -0.5vh;
			cursor: pointer;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 1.7vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.content {
			margin-top: 6vh;
			margin-left: 1vw;
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			line-height: 2vh;

			.words {
				width: 2vw;
				height: 2vh;
				color: #8aeaff;
			}

			.word {
				width: 4vw;
				height: 2vh;
				color: #8aeaff;
			}

			.shadow {
				width: 4vw;
				height: 2.2vh;
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 0vw;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
			}

			.num1 {
				color: #00ffb4;
				cursor: pointer;

			}

			.num2 {
				color: #8aeaff;
				cursor: pointer;
			}

			.first_line {
				.one {
					.one_div {
						margin-top: 1vh;

						.line2 {
							margin-top: 3.5vh;
						}

						.line3 {
							.num1 {
								width: 3vw;
								height: 2vh;
								cursor: pointer;
							}

							.num2 {
								width: 3vw;
								height: 2vh;
								cursor: pointer;
							}
						}
					}
				}

				.bg {
					width: 10vw;
					height: 10vh;
					background-image: url("~@/assets/images/RqBoiler/maintemperature.png");
					background-size: 100% 100%;

					.button {
						width: 1vw;
						height: 2vh;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 2vh;
						cursor: pointer;
					}

					.buttonx {
						margin-top: 1vh;
						margin-left: 2.7vw;
						background-color: #00e4ff;
						color: white;
						cursor: pointer;
					}

					.buttonk {
						margin-top: 5.3vh;
						margin-left: 1.5vw;
						background-color: #04ff57;
						color: white;
						cursor: pointer;
					}
				}

				.buttona {
					margin-top: 1vh;
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #00e4ff;
					color: white;
					cursor: pointer;
				}

				.four_div {
					margin-top: 1vh;

					.line2 {
						margin-top: 3.5vh;
					}
				}

			}

			.second {
				margin-top: 5vh;
			}

			.line_anniu {
				margin-top: 1vh;
				margin-left: 23vw;

				.anniu {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #04ff57;
					color: white;
					cursor: pointer;
				}
			}

		}

		.explain {
			width: 53vw;
			height: 12vh;
			border-top: 2px solid #bbd6e5;
			margin-top: 10vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #4d91a7;

			.explain_content {
				margin-top: 2vh;
				margin-left: 2vw;

				.explain1 {
					.blank {
						width: 2vw;
					}
				}

			}

		}

	}
</style>
